<script>
    /** @type {Home} */
    export let plan;
</script>

{#if plan.isSample || !plan.approved}
    <div class="absolute w-full h-full flex justify-center items-center">
        <div
            class="bg-black bg-opacity-65 text-white rounded px-4 py-2 text-2xl font-medium transform -rotate-22"
        >
            {plan.approved ? "sample" : "processing"}
        </div>
    </div>
{/if}

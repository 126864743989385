<script>
    import { onDestroy, onMount } from "svelte";
    import {
        cachedPlans,
        currentPlan,
        decodedAuthToken,
        leadCaptureComplete,
        shortlist,
        shortlistPlan,
        showPreviousPlan,
        unShortlistPlan,
        userIsAnonymous,
    } from "../store";
    import { fly } from "svelte/transition";
    import { getPlan, getPlanShareUrl } from "../api";
    import PlanDetailsAgents from "./PlanDetailsAgents.svelte";
    import { PlanVisibility, propertyTypeName } from "../constants";
    import PlanDetailsCatalogue from "./PlanDetailsCatalogue.svelte";
    import ArModeSelector from "./ARModeSelector.svelte";
    import PlanSpecIcons from "../lib/PlanSpecIcons.svelte";
    import * as Analytics from "../analytics";
    import SampleStamp from "../lib/SampleStamp.svelte";
    import nativeRequest, { defaultScheme } from "../nativeRequest";
    import LeadCaptureForm from "./LeadCaptureForm.svelte";
    import markdownit from "markdown-it";

    const md = markdownit();
    const mdDefaultLinkRender =
        md.renderer.rules.link_open ||
        function (tokens, idx, options, env, self) {
            return self.renderToken(tokens, idx, options);
        };
    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        // Replace all links with 'external_link' scheme so they open outside the app.
        // Otherwise it opens with the app webview and there's no back button!
        let hrefIndex = tokens[idx].attrIndex("href");
        if (hrefIndex >= 0) {
            let href = tokens[idx].attrs[hrefIndex][1];
            tokens[idx].attrs[hrefIndex][1] =
                `${defaultScheme}external_link?url=${encodeURIComponent(href)}`;
        }

        // Pass token to default renderer.
        return mdDefaultLinkRender(tokens, idx, options, env, self);
    };

    /** @type {Home} */ export let plan;
    let isShortlisted;
    let shareLoading = false;
    let selectingARMode = false;
    let shortlistable = false;
    let availableOffline = false;
    let needsUpdate = false;
    let enquiring = false;
    let earTag =
        plan.visibility === PlanVisibility.private
            ? "PRIVATE"
            : plan.visibility === PlanVisibility.team
              ? "TEAM"
              : null;
    /** @type {HTMLButtonElement} */ let shareButton;

    /** @type {HTMLElement} */ let scrollArea;
    $: if (plan && scrollArea) scrollArea.scroll({ top: 0 });

    $: isShortlisted = !!$shortlist?.find((h) => h.id === plan.id);

    $: shortlistable = !plan.homeAgents?.find(
        (a) => a.supplierAgent.contactEmail === $decodedAuthToken.sub
    );

    $: {
        const cached = $cachedPlans?.find((p) => p.id === plan.id.toString());
        availableOffline = !!cached;
        needsUpdate =
            !!cached &&
            cached.filename !== plan.augmentedRealityModel?.storageName;
    }

    onMount(async () => {
        Analytics.screen("Plan", "Mobile", {
            plan_id: plan.id,
        });

        document.querySelector("body").style.overflow = "hidden";

        plan = await getPlan(plan.id);
    });

    onDestroy(() => {
        document.querySelector("body").style.overflow = "auto";
    });

    // Source: https://stackoverflow.com/a/43467144
    function isValidUrl(str) {
        let url;
        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    async function doShare() {
        shareLoading = true;
        try {
            const url = await getPlanShareUrl($currentPlan.id);
            const rect = shareButton.getBoundingClientRect();
            await nativeRequest("share", {
                title: `${plan.name} by ${plan.supplierName}`,
                url,
                posX: rect.x + rect.width / 2,
                posY: rect.y + rect.height,
                arrowDir: "Any",
            });
            Analytics.track("Share", {
                plan_id: plan.id,
            });
        } finally {
            shareLoading = false;
        }
    }

    function formatBytes(bytes) {
        // Return bytes formatted as human-readable string.
        // Source: https://stackoverflow.com/a/18650828
        if (!bytes) return "---";
        if (bytes === 0) return "0 B";

        const k = 1024;
        const sizes = ["B", "KB", "MB", "GB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
    }

    async function visitExternalLink() {
        await nativeRequest("external_link", {
            url: plan.externalLink,
        });
    }
</script>

{#if enquiring}
    <LeadCaptureForm {plan} on:success={() => (enquiring = false)} />
{:else if selectingARMode}
    {#if import.meta.env.VITE_LEAD_CAPTURE === "true" && !$leadCaptureComplete && $userIsAnonymous}
        <LeadCaptureForm {plan} cancelText="No thank you" />
    {:else}
        <ArModeSelector on:cancel={() => (selectingARMode = false)} {plan} />
    {/if}
{/if}

<div
    class="PlanDetails z-100 fixed top-0 left-0 w-full h-full px-4 pt-[calc(var(--safe-area-top)+4rem)] pb-$safe-area-bottom bg-$color-bg-body overflow-y-auto"
    transition:fly={{ y: 350, duration: 280 }}
    bind:this={scrollArea}
>
    <div
        class="bg-$color-bg-header bg-opacity-85 z-102 fixed top-0 left-0 w-full h-[calc(var(--safe-area-top)+3rem)] pt-[calc(var(--safe-area-top)+0.5rem)] pb-0.5rem px-3 pr-5 backdrop-filter backdrop-blur-lg border-b-1 border-b-true-gray-300 rounded-b-lg touch-none flex flex-row items-center justify-between text-$color-primary"
    >
        <button
            class="flex flex-row items-center text-lg"
            on:click={showPreviousPlan}
        >
            <span class="material-symbols-rounded text-3xl">chevron_left</span> Back
        </button>
        <div class="flex flex-row gap-7">
            {#if plan.visibility !== PlanVisibility.team}
                <button
                    bind:this={shareButton}
                    class="material-symbols-rounded text-3xl"
                    on:click={doShare}
                    disabled={shareLoading}>ios_share</button
                >
            {/if}
            {#if shortlistable && isShortlisted}
                <button
                    class="material-symbols-rounded material-symbols-rounded-filled text-3xl"
                    on:click={() => unShortlistPlan(plan.id)}
                >
                    favorite
                </button>
            {:else if shortlistable}
                <button
                    class="material-symbols-rounded text-3xl"
                    on:click={() => shortlistPlan(plan.id)}
                >
                    favorite
                </button>
            {/if}
        </div>
    </div>
    <!-- "transform: translateZ(0)" is to fix an issue with Safari where the rounded corners do not mask correctly. -->
    <div
        class="bg-white text-black flex flex-col justify-between items-stretch rounded-lg rounded-tr-4xl overflow-hidden shadow-md relative max-w-170 mx-auto"
        style="transform: translateZ(0); --thumb-width: 22rem;"
    >
        {#if earTag}
            <div
                class="bg-$color-bg-eartag text-$color-text-eartag text-center text-xs p-0.5 flex flex-col justify-end w-40 h-14 absolute top-0 right-0 transform origin-top rotate-45 translate-x-20 z-10"
            >
                {earTag}
            </div>
        {/if}
        <div class="flex flex-row items-center h-12 p-2">
            {#if plan.supplierLogo}
                <img
                    class="h-full object-cover"
                    src={plan.supplierLogo.thumbnail}
                    alt="{plan.supplierName} logo"
                />
            {/if}
        </div>
        <div
            class="flex-grow bg-cover bg-center flex flex-col justify-end h-50 sm:h-70 md:h-90 relative"
            style="background-image: url('{plan.currentPhoto?.uploadUrl}{plan
                .currentPhoto?.sasToken}');"
        >
            <SampleStamp {plan} />
        </div>
        <div class="flex flex-col p-3 gap-4">
            <div class="text-2xl font-medium">{plan.name}</div>
            {#if import.meta.env.VITE_HIDE_PROPERTY_TYPE !== "true"}
                <div class="text-sm">
                    {propertyTypeName[plan.propertyTypeId]}
                </div>
            {/if}
            <div class="flex flex-row justify-between items-center">
                {#if import.meta.env.VITE_HIDE_ALL_SPECS !== "true"}
                    <div class="w-35">
                        <PlanSpecIcons {plan} />
                    </div>
                {/if}
                <!-- AR Button -->
                <button
                    class="btn flex flex-row justify-center items-center {import.meta
                        .env.VITE_HIDE_ALL_SPECS === 'true'
                        ? 'flex-grow'
                        : 'flex-grow-0'} w-39 gap-2 text-lg"
                    on:click={() => (selectingARMode = true)}
                >
                    <span class="material-symbols-rounded text-2.2rem">
                        view_in_ar
                    </span>
                    <div
                        class="flex flex-col items-center justify-center pb-0.1 pt-1"
                    >
                        <span class="font-semibold text-1rem"> AR VIEW </span>
                        <span style="font-size: 0.7rem; line-height: 1.1rem;">
                            {#if availableOffline && !needsUpdate}
                                Downloaded
                            {:else}
                                {formatBytes(plan.augmentedRealityModel?.bytes)}
                            {/if}
                        </span>
                    </div>
                </button>
            </div>
            <hr class="mt-3 mb-2" />
            {#if plan.tagline?.length}
                <div class="font-bold">{plan.tagline}</div>
            {/if}
            {#if plan.description?.length}
                <div id="description" class="leading-5 whitespace-pre-wrap">
                    {#if import.meta.env.VITE_MARKDOWN_DESCRIPTION === "true"}
                        {@html md.render(plan.description)}
                    {:else}
                        {plan.description}
                    {/if}
                </div>
            {/if}
            <div class="text-xs my-2 text-true-gray-700">
                {@html import.meta.env.VITE_DISCLAIMER}
            </div>
            {#if import.meta.env.VITE_LEAD_CAPTURE === "true"}
                <button
                    class="btn btn-secondary h-12 w-60 mx-auto font-bold"
                    on:click={() => (enquiring = true)}
                >
                    Enquire Now
                </button>
            {/if}
            {#if isValidUrl(plan.externalLink)}
                <button
                    on:click={visitExternalLink}
                    class="btn max-w-34 text-sm"
                >
                    Visit Website
                </button>
            {/if}
            {#if !!plan.homeAgents?.length}
                <hr class="mt-3 mb-2" />
                <div class="text-true-gray-600">Contact us</div>
                <PlanDetailsAgents agents={plan.homeAgents} {plan} />
            {/if}
        </div>
    </div>
    {#if plan.visibility >= PlanVisibility.catalogue}
        <PlanDetailsCatalogue {plan} />
    {/if}
    <div class="h-1">&nbsp;</div>
</div>

<style>
    :global(#description h1) {
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: bold;
    }

    :global(#description h2) {
        font-size: 1.4rem;
        line-height: 1.4rem;
        font-weight: bold;
    }

    :global(#description h3) {
        font-size: 1.3rem;
        line-height: 1.3rem;
        font-weight: bold;
    }

    :global(#description h4) {
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: bold;
    }

    :global(#description h5) {
        font-size: 1.1rem;
        line-height: 1.1rem;
        font-weight: bold;
    }

    :global(#description a) {
        @apply text-$color-primary underline;
    }
</style>

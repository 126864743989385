<script>
    import { onMount } from "svelte";
    import { logout } from "../api";
    import {
        auth,
        cachedPlans,
        leadCaptureComplete,
        showPlanId,
    } from "../store";
    import nativeRequest from "../nativeRequest";

    let selectedPlanId = "";
    // let selectedServer;

    // onMount(() => {
    //     const override = window.localStorage.getItem("apiOverride");
    //     if (override) selectedServer = override;
    // });

    // function onServerChange(e) {
    //     if (
    //         confirm(
    //             `This will log you out and change the server to ${e.target.value}. Are you sure?`
    //         )
    //     ) {
    //         if (e.target.value === "prod")
    //             window.localStorage.removeItem("apiOverride");
    //         else window.localStorage.setItem("apiOverride", e.target.value);
    //         logout();
    //     } else {
    //         e.target.value = selectedServer;
    //     }
    // }

    function doLogout() {
        if (confirm("Are you sure you want to logout?")) logout();
    }

    function resetLeadForm() {
        if (confirm("Are you sure you want to reset the lead form?")) {
            $leadCaptureComplete = false;
        }
    }

    async function showSelectedPlan() {
        try {
            await showPlanId(selectedPlanId);
        } catch (e) {
            alert(`Failed to show plan ${selectedPlanId}: ${e}`);
        }
    }

    /**
     * @param {string | HTMLElement} [str]
     */
    function clip(str) {
        try {
            if (str instanceof HTMLElement) str = str.innerText;
            navigator.clipboard.writeText(str);
        } catch (e) {
            alert("Failed to copy to clipboard.");
        }
    }
</script>

<div class="flex flex-col gap-2 p-6 max-w-full break-words">
    <b>API:</b>
    <p>{import.meta.env.VITE_API_URL}</p>
    <b>User ID:</b>
    <p>{$auth.id}</p>
    <b>Country:</b>
    <p>{$auth.user.countryId}</p>
    <b>Profile complete:</b>
    <p>{$auth.user.profileComplete}</p>
    <b
        >Auth token: <button
            class="underline"
            on:click={() => clip($auth.auth_token)}>Copy</button
        ></b
    >
    <p>{$auth.auth_token}</p>
    <b>Mode:</b>
    <p>{import.meta.env.MODE}</p>
    <b>Webview Commit:</b>
    <p>{import.meta.env.COMMIT_HASH}</p>
    {#await nativeRequest("info") then unityInfo}
        <b>Version:</b>
        <p>{unityInfo.version}</p>
        <b>Build GUID:</b>
        <p>{unityInfo.buildGUID}</p>
        <b>Unity Project Commit:</b>
        <p>{unityInfo.projectCommit?.substring(0, 7)}</p>
        <b>Unity Core Commit:</b>
        <p>{unityInfo.coreCommit?.substring(0, 7)}</p>
    {:catch}
        <span>Could not get native info.</span>
    {/await}
    <!-- <b>Change API server:</b>
    <select
        class="h-10"
        on:change|preventDefault={onServerChange}
        bind:value={selectedServer}
    >
        <option value="prod">Production</option>
        <option value="dev">Development</option>
    </select> -->
    <input
        type="number"
        class="input-field mt-4"
        placeholder="Plan ID..."
        bind:value={selectedPlanId}
    />
    <button
        class="btn mb-4"
        disabled={!selectedPlanId}
        on:click={showSelectedPlan}
    >
        Show plan
    </button>
    {#if import.meta.env.VITE_LEAD_CAPTURE === "true"}
        <button class="btn" on:click={resetLeadForm}>Reset lead form</button>
    {/if}
    <button class="btn" on:click={doLogout}>Logout</button>
    <button
        class="btn"
        on:click={async () => {
            await nativeRequest("clear_cache");
            await cachedPlans.reload();
        }}
    >
        Clear model cache
    </button>
</div>

<style>
    p {
        @apply select-text;
    }
</style>

<script>
    import * as Analytics from "../analytics";
    import { getPlanShareUrl } from "../api";
    import nativeRequest from "../nativeRequest";

    /** @type HomeAgent[] */
    export let agents;
    /** @type Home */
    export let plan;

    /** @type HomeAgent */
    let selectedAgent;

    function track(method) {
        Analytics.track("Contacted Agent", {
            method,
            plan_id: plan.id,
            agent_user_id: selectedAgent.supplierAgentId,
        });
    }

    async function sendEmail() {
        const shareLink = await getPlanShareUrl(plan.id);

        const replaceParams = (s) =>
            encodeURIComponent(
                s.replace(/{name}/g, plan.name).replace(/{link}/g, shareLink)
            );

        await nativeRequest(
            selectedAgent.supplierAgent.contactEmail,
            `subject=${replaceParams(
                import.meta.env.VITE_AGENT_EMAIL_SUBJECT
            )}&body=${replaceParams(import.meta.env.VITE_AGENT_EMAIL_BODY)}`,
            "mailto:"
        );

        track("email");
    }
</script>

{#if !selectedAgent}
    <!-- Scroll area -->
    <div class="flex overflow-x-auto pb-2">
        {#each agents as agent}
            <button
                class="flex flex-shrink-0 flex-col items-center w-1/3 sm:w-1/4 md:w-1/5 gap-3"
                on:click={() => (selectedAgent = agent)}
            >
                <div
                    class="w-25 h-25 bg-cover bg-center rounded-full shadow-md shadow-true-gray-300"
                    style:background-image="url({agent.supplierAgent.picture
                        .thumbnail})"
                />
                <div class="flex flex-col items-center">
                    <span
                        class="font-bold text-sm mb-0.5"
                        style="line-height: 1rem;"
                    >
                        {agent.supplierAgent.name}
                    </span>
                    <span class="text-xs text-true-gray-600">
                        {agent.supplierAgent.location ?? ""}
                    </span>
                </div>
            </button>
        {/each}
    </div>
{:else}
    <div class="flex flex-col gap-3 relative">
        <div class="flex flex-row items-center gap-4">
            <div
                class="w-25 h-25 bg-cover bg-center rounded-full shadow-md shadow-true-gray-300"
                style:background-image="url({selectedAgent.supplierAgent.picture
                    .thumbnail})"
            />
            <div class="flex flex-col">
                <span class="font-bold">
                    {selectedAgent.supplierAgent.name}
                </span>
                <span class="text-sm text-true-gray-600">
                    {selectedAgent.supplierAgent.location ?? ""}
                </span>
            </div>
        </div>
        <div class="flex flex-row gap-3">
            <a
                href="tel:{selectedAgent.supplierAgent.contactPhone}"
                class="btn w-full"
                on:click={() => track("call")}
            >
                Call
            </a>
            {#if import.meta.env.VITE_LEAD_CAPTURE !== "true"}
                <button class="btn w-full" on:click={sendEmail}> Email </button>
            {/if}
        </div>
        <button
            class="material-symbols-rounded absolute right-0 top-0"
            on:click={() => (selectedAgent = null)}>close</button
        >
    </div>
{/if}

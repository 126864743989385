import axios from "axios";
import { get } from "svelte/store";
import { auth, decodedAuthToken } from "./store.js";
import {
    captureMessage,
    setUser as setSentryUser,
    startTransaction,
} from "@sentry/svelte";
import * as Analytics from "./analytics";
import nativeRequest from "./nativeRequest";

const AUTH_STORAGE_KEY = "auth";

const ax = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
});

console.log("API url: " + ax.defaults.baseURL);

ax.interceptors.request.use(
    (config) => {
        const transaction = startTransaction({
            op: "http.request",
            name: `${config.method?.toUpperCase()}: ${config.url}`,
        });
        // @ts-ignore
        config.metadata = { transaction };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

ax.interceptors.response.use(
    (response) => {
        const { config } = response;
        // @ts-ignore
        if (config.metadata?.transaction) {
            // @ts-ignore
            config.metadata.transaction.finish();
        }
        return response;
    },
    async (error) => {
        const config = error.config;
        if (error.response) {
            if (
                error.response.status === 401 &&
                !config.url.endsWith("Auth/Refresh") &&
                !config.url.endsWith("Auth/Login") &&
                !!get(auth)
            ) {
                console.log("Refreshing token");
                await refreshAuthToken();
                config.headers["Authorization"] =
                    ax.defaults.headers.common["Authorization"];
                return ax(config);
            } else if (error.response.status === 401) {
                logout();
            }
        }
        return Promise.reject(error);
    }
);

/** @param {Auth} data */
async function setAuthFromResponse(data) {
    window.localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(data));
    ax.defaults.headers["Authorization"] = "Bearer " + data.auth_token;
    await setAuth(data);
}

export function checkForAuth() {
    const dataString = window.localStorage.getItem(AUTH_STORAGE_KEY);
    if (dataString) {
        /** @type Auth */ const data = JSON.parse(dataString);
        ax.defaults.headers["Authorization"] = "Bearer " + data.auth_token;
        setAuth(data);
    }
    return dataString !== null;
}

/** @param {Auth} a */
async function setAuth(a) {
    if (!a) return;
    auth.set(a);
    const user = {
        id: a.user.id,
        email: get(decodedAuthToken).sub,
        role: !!a.user.roles ? a.user.roles[0] : null,
    };
    setSentryUser(user);
    const orgId = get(decodedAuthToken).SupplierId;
    if (orgId) user.org_id = orgId;
    await nativeRequest("set_user", user);
}

export async function register(
    email,
    password,
    firstName,
    lastName,
    phoneNumber
) {
    await ax.post("/api/v3/Account", {
        password,
        baseUser: {
            email,
            firstName,
            lastName,
            phoneNumber,
        },
    });
    await login(email, password);
}

export async function login(username, password) {
    if (!username || !password) {
        captureMessage(
            "Username or password not provided for `login` api",
            "error"
        );
        return;
    }

    const res = await ax.post("/api/v2/Auth/Login", {
        username,
        password,
        appConfigId: import.meta.env.VITE_APP_CONFIG_ID,
    });
    await setAuthFromResponse(res.data);
    Analytics.track("Sign-in", { method: "password" });
}

export async function loginAnon(homeId, countryId) {
    // Must have one of homeId or countryId
    if (!countryId && !homeId) {
        captureMessage(
            "countryId or homeId not provided for `loginAnon` api",
            "error"
        );
        return;
    }

    const res = await ax.post("/api/v2/Auth/Login/Anonymous", {
        homeId,
        countryId,
        appConfigId: import.meta.env.VITE_APP_CONFIG_ID,
        favorites: import.meta.env.VITE_ANON_SAMPLES?.split(",").map((x) =>
            parseInt(x)
        ),
    });
    await setAuthFromResponse(res.data);
}

export async function loginWithToken(userId, token) {
    if (!userId || !token || get(auth)?.id === userId) return;

    const res = await ax.post("/api/v2/Auth/Login/Token", {
        userId,
        token,
        appConfigId: import.meta.env.VITE_APP_CONFIG_ID,
    });
    await setAuthFromResponse(res.data);
    Analytics.track("Sign-in", { method: "link" });
}

async function refreshAuthToken() {
    const res = await ax.post("/api/v2/Auth/Refresh", {
        refresh_token: get(auth).refresh_token,
    });
    await setAuthFromResponse(res.data);
}

export async function logout() {
    window.localStorage.removeItem(AUTH_STORAGE_KEY);
    auth.set(null);
    Analytics.reset();
}

export async function sendPasswordResetEmail(email) {
    await ax.post("/api/v3/Account/Recover", {
        email,
    });
}

/** @returns {Promise<Home[]>} */
export async function getShortlist() {
    return (await ax.get("/api/v4/Account/Favorite")).data;
}

export async function postShortlist(planId) {
    ax.post("/api/v3/Account/Favorite/" + planId);
}

export async function deleteShortlist(planId) {
    ax.delete("/api/v3/Account/Favorite/" + planId);
}

/** @returns {Promise<Home[]>} */
export async function getDiscover(
    page = 1,
    pageSize = 10,
    orderSeed = "",
    filters = null,
    sorts = null
) {
    return (
        await ax.get("/api/v4/Home/Filter", {
            params: {
                page,
                pageSize,
                filters,
                sorts,
                appConfigId: import.meta.env.VITE_APP_CONFIG_ID,
            },
            headers: {
                orderSeed,
            },
        })
    ).data;
}

/**
 * @param {string | number} id
 * @returns {Promise<Home>}
 */
export async function getPlan(id) {
    return (await ax.get("/api/v4/Home/" + id)).data;
}

/**
 * @param {string | number} id
 * @returns {Promise<string>}
 */
export async function getPlanShareUrl(id) {
    return (await ax.get("/api/v3/Frame/FirebaseHomeLink/" + id)).data
        .shortLink;
}

/** @returns {Promise<Home[]>} */
export async function getPlanCatalogue(planId) {
    return (await ax.get("/api/v4/Home/Related/" + planId)).data;
}

/** @returns {Promise<UserConfig>} */
export async function getUserConfig() {
    return (await ax.get("/api/v3/Account/Config")).data;
}

/** @returns {Promise<Home[]>} */
export async function getUserCatalogue(
    page = 1,
    pageSize = 10,
    orderSeed = "",
    filters = null,
    sorts = null
) {
    return (
        await ax.get("/api/v4/Home/Catalogue", {
            params: {
                page,
                pageSize,
                filters,
                sorts,
            },
            headers: {
                orderSeed,
            },
        })
    ).data;
}

/** @returns {Promise<Account>} */
export async function getAccount() {
    try {
        return (await ax.get("/api/v3/Account")).data;
    } catch (e) {
        if (e?.response?.status === 403) {
            // Account not found, likely the user is anonymous
            return null;
        }
        throw e;
    }
}

/**
 * @param {boolean} status
 * @returns {Promise<{status: string}>}
 */
export async function postPushStatus(status) {
    return (await ax.post("/api/v4/Account/Push", { status })).data;
}

export async function requestAccountDeletion() {
    await ax.post("/api/v3/Account/RequestDeletion");
}

/** @returns {Promise<PropertyType[]>} */
export async function getPropertyTypes() {
    return (await ax.get("/api/v3/Static/PropertyTypes")).data;
}

/** @returns {Promise<Region[]>} */
export async function getRegions() {
    return (await ax.get("/api/v3/Static/Regions")).data;
}

/** @returns {Promise<District[]>} */
export async function getRegionDistricts(regionId) {
    return (await ax.get(`/api/v3/Static/Districts/${regionId}`)).data;
}

/** @returns {Promise<Suburb[]>} */
export async function getDistrictSuburbs(districtId) {
    return (await ax.get(`/api/v3/Static/Suburbs/${districtId}`)).data;
}

/** @returns {Promise<Country[]>} */
export async function getCountries() {
    return (await ax.get("/api/v3/Static/Countries")).data;
}

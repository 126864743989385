<script>
    import { createEventDispatcher } from "svelte";
    import { fade } from "svelte/transition";
    import { auth, leadCaptureComplete } from "../store";

    const dispatch = createEventDispatcher();

    /** @type {Home} */ export let plan;
    export let submitText = "Submit";
    export let cancelText = "Cancel";

    let busy = false;
    let error = false;

    async function submit(e) {
        busy = true;
        error = false;
        try {
            const res = await fetch(
                "https://hook.us1.make.com/8pamjqi4pn8n5idtedww4l55snemkj2i",
                {
                    method: "POST",
                    // @ts-ignore
                    body: new URLSearchParams(new FormData(e.target)),
                }
            );
            if (!res.ok) error = true;
            else {
                finish();
            }
        } catch (e) {
            error = true;
        } finally {
            busy = false;
        }
    }

    function finish() {
        dispatch("success");
        $leadCaptureComplete = true;
    }
</script>

<div
    class="fixed flex justify-center left-0 top-0 w-full h-full bg-black bg-opacity-90 backdrop-filter backdrop-blur-lg z-200 pt-$safe-area-top pb-$safe-area-bottom px-5"
    transition:fade={{ duration: 100 }}
>
    <form
        class="flex flex-col gap-4 max-w-150"
        on:submit|preventDefault={submit}
    >
        <h2>Please enter your details:</h2>
        <input type="hidden" name="planId" value={plan.id} />
        <input type="hidden" name="userId" value={$auth.id} />
        <div class="field">
            <input type="text" class="input-field" name="name" required />
            <label for="name">Name<span class="text-red-600">*</span></label>
        </div>
        <div class="field">
            <input type="email" class="input-field" name="email" required />
            <label for="email">Email<span class="text-red-600">*</span></label>
        </div>
        <div class="field">
            <input type="tel" class="input-field" name="phone" />
            <label for="phone">Phone number</label>
        </div>
        <div class="text-red-500" hidden={!error}>
            Oops, something went wrong. Please try again.
        </div>
        <div id="disclaimer" class="text-sm text-true-gray-400">
            {@html import.meta.env.VITE_LEAD_CAPTURE_DISCLAIMER}
        </div>
        <button
            type="submit"
            class="btn bg-$color-primary text-$color-text-on-primary"
            disabled={busy}
        >
            {submitText}
        </button>
        <button
            type="button"
            class="btn-text text-$color-primary"
            on:click={finish}
            disabled={busy}
        >
            {cancelText}
        </button>
    </form>
</div>

<style>
    .field {
        @apply flex flex-col;
    }

    :global(#disclaimer a) {
        text-decoration: underline !important;
    }
</style>

<script>
    import { createEventDispatcher } from "svelte";
    import { fade, scale } from "svelte/transition";
    import { quartOut } from "svelte/easing";

    export let confirm = false;
    export let ok = false;
    export let header = "";
    export let body = "";
    export let open = true;

    const dispatch = createEventDispatcher();

    /**
     * @param {boolean} result
     */
    function doResult(result) {
        dispatch("result", result);
        open = false;
    }
</script>

{#if open}
    <div
        class="fixed w-full h-full top-0 left-0 bg-black bg-opacity-50 z-10000"
        transition:fade={{ duration: 300, easing: quartOut }}
        on:click={() => {
            if (!confirm) doResult(false);
            else open = false;
        }}
        aria-hidden
    />
    <div
        class="Modal fixed top-1/2 left-1/2 w-76 max-w-full p-7 bg-white text-black shadow shadow-xl flex flex-col gap-2 transform -translate-x-1/2 -translate-y-1/2 rounded rounded-2xl z-10001"
        transition:scale={{
            duration: 300,
            opacity: 0,
            start: 0.8,
            easing: quartOut,
        }}
    >
        {#if $$slots.header || header}
            <div class="font-bold">
                <slot name="header">{header}</slot>
            </div>
        {/if}
        <slot name="body">{body}</slot>

        <div class="flex flex-row gap-2 justify-end">
            {#if confirm}
                <button
                    class="btn btn-secondary px-5"
                    on:click={() => doResult(false)}
                >
                    <slot name="noBtnText">No</slot>
                </button>
                <button class="btn px-5" on:click={() => doResult(true)}>
                    <slot name="yesBtnText">Yes</slot>
                </button>
            {/if}
            {#if ok}
                <button class="btn px-5" on:click={() => doResult(true)}>
                    <slot name="okBtnText">Ok</slot>
                </button>
            {/if}
        </div>
    </div>
{/if}

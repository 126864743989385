export const propertyTypeName = {
    3: "Plan/Concept",
    4: "House & Land",
    5: "Prefab/Modular",
    null: "",
    undefined: "",
};

export const PlanVisibility = {
    private: 1,
    team: 2,
    catalogue: 3,
    discoverable: 4,
};

// This is to map locale regions to the country code from the API.
// Would be good if this came from the API directly.
export const localeRegionToCountryCode = {
    NZ: 1,
    US: 2,
    GB: 3,
    AU: 4,
    ZA: 5,
    CA: 6,
    SG: 7,
};

export const FloorAreas = [
    {
        min: 0,
        max: 0,
    },
    {
        min: 0,
        max: 50,
    },
    {
        min: 50,
        max: 100,
    },
    {
        min: 100,
        max: 200,
    },
    {
        min: 200,
        max: 250,
    },
    {
        min: 250,
        max: 300,
    },
    {
        min: 300,
        max: 350,
    },
    {
        min: 350,
        max: -1,
    },
];

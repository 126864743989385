<script>
    import StartupAuth from "./components/StartupAuth.svelte";
    import PlanBrowser from "./components/PlanBrowser.svelte";
    import { onMount } from "svelte";
    import { checkForAuth, loginAnon, loginWithToken } from "./api";
    import {
        auth,
        currentPlan,
        onboardingComplete,
        safeArea,
        shortlistPlan,
        showDevOptions,
        showPlanId,
    } from "./store";
    import PlanDetails from "./components/PlanDetails.svelte";
    import Onboarding from "./lib/Onboarding.svelte";
    import { get } from "svelte/store";
    import Modal from "./lib/Modal.svelte";
    import nativeRequest from "./nativeRequest";
    import { captureMessage } from "@sentry/svelte";
    import { localeRegionToCountryCode } from "./constants";

    $: setSafeAreaVars(JSON.parse($safeArea ?? "{}"));

    let showOnboarding = false;
    $: showOnboarding =
        !$onboardingComplete &&
        import.meta.env.VITE_ONBOARDING_DISABLED !== "true";

    let isOffline = false;
    let startTime = Date.now();

    onMount(() => {
        console.log("Startup time:", startTime);
        tryGetSafeAreaFromParams();
        checkForAuth();
    });

    function tryGetSafeAreaFromParams() {
        let top = 0;
        let bottom = 0;

        var params = new URLSearchParams(window.location.search);
        if (params.has("safeAreaTop") && params.has("safeAreaBottom")) {
            top = parseInt(params.get("safeAreaTop"));
            bottom = parseInt(params.get("safeAreaBottom"));
            safeArea.set(JSON.stringify({ top, bottom }));
        }
    }

    /**
     * @param {{top: number, bottom: number}} safe
     */
    function setSafeAreaVars(safe) {
        const top = safe?.top ?? 0;
        const bottom = safe?.bottom ?? 0;
        document.body.style.setProperty(
            "--safe-area-top",
            `${top / window.devicePixelRatio}px`
        );
        document.body.style.setProperty(
            "--safe-area-bottom",
            `${bottom / window.devicePixelRatio}px`
        );
        console.log(
            `Did safe area: ${top}, ${bottom} -> ${
                top / window.devicePixelRatio
            }px, ${bottom / window.devicePixelRatio}px`
        );
    }

    let deepLinkError;

    /**
     * @param {{ detail: DeepLink; }} e
     */
    async function handleDeepLinks({ detail: dl }) {
        if (!dl) return;

        console.log("DeepLink event", dl);

        if (
            !!import.meta.env.VITE_DEV_SECRET &&
            dl.dev === import.meta.env.VITE_DEV_SECRET
        )
            $showDevOptions = true;

        if (dl.userId && dl.loginToken) {
            try {
                await loginWithToken(dl.userId, dl.loginToken);
            } catch (e) {
                deepLinkError =
                    "Unable to login via link. It may be invalid or have expired.";
                return;
            }
        }

        if (dl.planId) {
            if (!get(auth))
                await loginAnon(
                    dl.planId,
                    !!import.meta.env.VITE_DEFAULT_COUNTRY
                        ? localeRegionToCountryCode[
                              import.meta.env.VITE_DEFAULT_COUNTRY
                          ]
                        : null
                );
            await Promise.all([
                shortlistPlan(dl.planId),
                showPlanId(dl.planId),
            ]);
        }
    }

    function onVisibilityChanged() {
        if (
            document.visibilityState === "visible" &&
            Date.now() - startTime > 24 * 60 * 60 * 1000
        ) {
            captureMessage(
                "Page reloaded due to being in background for more than 24 hours."
            );
            window.location.reload();
        }
    }
</script>

<svelte:document
    on:deeplink={handleDeepLinks}
    on:visibilitychange={onVisibilityChanged}
/>
<svelte:window
    on:offline={() => (isOffline = true)}
    on:online={() => (isOffline = false)}
/>

<div id="tempp" class="max-h-screen {showOnboarding ? 'overflow-hidden' : ''}">
    <main
        class="pt-[calc(var(--safe-area-top)+3rem)] pb-[calc(var(--safe-area-bottom)+4rem)] select-none"
    >
        {#if $auth}
            <PlanBrowser />
            {#if showOnboarding}
                <Onboarding on:finish={() => ($onboardingComplete = true)} />
            {/if}
        {:else}
            <StartupAuth />
        {/if}

        {#if $currentPlan}
            <PlanDetails plan={$currentPlan} />
        {/if}
    </main>
</div>

{#if deepLinkError}
    <Modal
        ok
        header="Error"
        body={deepLinkError}
        on:result={() => (deepLinkError = null)}
    />
{/if}

{#if isOffline}
    <Modal
        confirm
        header="Network Lost"
        body="You appear to be offline, would you like to switch to offline mode?"
        on:result={(r) => {
            if (r.detail) nativeRequest("set_offline_mode");
        }}
    />
{/if}

<style windi:preflights:global windi:safelist:global>
    :global(.material-symbols-rounded) {
        font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
    }

    :global(.material-symbols-rounded-filled) {
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48;
    }

    :global(.material-symbols-rounded:disabled) {
        @apply opacity-50;
    }

    :global(.link) {
        @apply underline;
    }

    :global(h1) {
        @apply font-bold text-4xl;
    }

    :global(h2) {
        @apply font-bold text-3xl;
    }

    :global(h3) {
        @apply font-bold text-2xl;
    }

    :global(h4) {
        @apply font-bold text-xl;
    }

    :global(h5) {
        @apply font-bold text-lg;
    }

    :global(.btn) {
        @apply bg-$color-bg-btn rounded-full p-2 text-$color-btn text-center select-none cursor-pointer shadow-md;
    }

    :global(.btn:disabled) {
        @apply opacity-50 cursor-default;
    }

    :global(.btn-secondary) {
        @apply bg-$color-bg-btn-secondary text-center text-$color-btn-secondary border-$color-btn-secondary border-1.4px;
    }

    :global(.btn-secondary-danger) {
        @apply bg-$color-bg-btn-danger text-center text-$color-btn-danger border-$color-btn-danger border-1.4px;
    }

    :global(.btn-text) {
        @apply text-$color-btn-text text-center p-1;
    }

    :global(.input-field) {
        -webkit-appearance: none; /* Fix lack of shadows on Safari */
        @apply rounded-full px-4 py-2 shadow-md text-black;
    }

    :global(select) {
        @apply rounded-full px-4 py-2 shadow-md text-center text-black;
    }

    :global(ul) {
        @apply ml-8 list-circle;
    }

    :global(ol) {
        @apply ml-8 list-decimal;
    }
</style>

<script>
    import { onDestroy, onMount } from "svelte";
    import Filter from "../components/Filter.svelte";
    import PlanCard from "../lib/PlanCard.svelte";
    import Spinner from "../lib/Spinner.svelte";
    import PullToRefresh from "../pulltorefreshjs";
    import { fly } from "svelte/transition";
    import { getFilterString } from "../utils";
    import { showPlan } from "../store";
    import * as Analytics from "../analytics";

    /** @type {import("../planCatalogueStore").PlanCatalogueStore} */
    export let store;
    let { isInitialized, plans } = store;
    /** @type {import("@square/svelte-store").Writable<FilterConfig>} */
    export let filterStore;
    export let showPrivateToggle = false;
    export let analyticsName;

    let scrollHeightTarget = 5000;

    let loadingMore = false;
    let reachedEnd = false;
    let refreshPtr;
    let showingFilters = false;

    /** @type {Filter} */ let filter;

    onMount(() => {
        Analytics.screen(analyticsName, "Mobile");

        if (!$isInitialized) {
            setFilters();
            store.refresh();
        }

        window.addEventListener("scroll", onScroll);

        refreshPtr = PullToRefresh.init({
            mainElement: "#pullToRefreshWrapper",
            triggerElement: "#pullToRefreshWrapper",
            onRefresh() {
                store.refresh();
            },
            iconArrow: "refresh",
            iconRefreshing: "sync",
            distThreshold: 70,
            distMax: 100,
            distReload: 70,
            distIgnore: 0,
            refreshTimeout: 300,
        });
    });

    onDestroy(() => {
        window.removeEventListener("scroll", onScroll);
        refreshPtr.destroy();
    });

    async function onScroll() {
        if (
            !loadingMore &&
            window.innerHeight + window.scrollY >= scrollHeightTarget - 200
        ) {
            loadingMore = true;
            reachedEnd = !(await store.loadMore());
            loadingMore = false;
        }
    }

    export function showFilters() {
        showingFilters = true;
        // console.log("showFilters", $filterStore);
    }

    function hideFilters() {
        showingFilters = false;
    }

    function setFilters() {
        $filterStore = filter?.exportConfig();
        // console.log("exported", $filterStore);
        // console.log("filter string", $filterStore.getFilterString());
        store.filters = getFilterString($filterStore);
    }

    function submitFilter() {
        setFilters();
        hideFilters();
        store.refresh(false);
    }

    function resetFilter() {
        filter.reset();
    }
</script>

<div
    bind:offsetHeight={scrollHeightTarget}
    id="pullToRefreshWrapper"
    class="flex flex-col"
>
    {#if !$isInitialized}
        <Spinner />
    {:else}
        <div class="flex flex-row flex-wrap justify-evenly gap-5 p-5">
            {#each $plans.filter((h) => showPrivateToggle || h.visibility !== 1) as plan (plan.id)}
                <div
                    class="w-85 md:w-[calc(50%-2.5rem)] xl:w-[calc(33%-2.5rem)] h-50 md:h-60 lg:h-70"
                >
                    <PlanCard {plan} on:click={() => showPlan(plan)} />
                </div>
            {/each}
        </div>
        <div class="flex flex-col h-10 mb-12">
            {#if loadingMore}
                <Spinner />
            {/if}
            {#if reachedEnd}
                <div class="text-center">No more plans</div>
            {/if}
        </div>
    {/if}
</div>

{#if showingFilters}
    <div
        class="flex flex-col z-100 fixed top-0 left-0 w-full h-full pt-[calc(var(--safe-area-top)+1.25rem)] pb-$safe-area-bottom bg-$color-bg-body overflow-x-hidden overflow-y-auto"
        transition:fly={{ y: 1000, duration: 280 }}
    >
        <button class="btn-text max-w-18 text-left mx-5" on:click={hideFilters}>
            Cancel
        </button>
        <Filter bind:this={filter} config={$filterStore} {showPrivateToggle} />
        <div class="flex flex-row mx-5 gap-5">
            <button class="btn btn-secondary flex-grow" on:click={resetFilter}>
                Reset
            </button>
            <button class="btn flex-grow" on:click={submitFilter}>
                Submit
            </button>
        </div>
    </div>
{/if}

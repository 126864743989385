/**
 * @typedef NativeRequest
 * @property {string} path
 * @property {any?} params
 * @property {(value: any) => void} resolve
 * @property {(reason?: any) => void} reject
 */

export const defaultScheme = "homearweb://";

/** @type {NativeRequest[]} */
const nativeRequestQueue = [];
/** @type {NativeRequest} */
let currentNativeRequest = null;

// @ts-ignore
window.nativeResponse = (e) => {
    if (!currentNativeRequest) return;

    console.log("nativeResponse", e);
    currentNativeRequest.resolve(e);
    currentNativeRequest = null;
    processNativeRequestQueue();
};

function processNativeRequestQueue() {
    if (currentNativeRequest || nativeRequestQueue.length === 0) return;

    const req = nativeRequestQueue.shift();
    currentNativeRequest = req;

    let url = req.path;
    if (req.params && typeof req.params === "object") {
        url += "?" + new URLSearchParams(req.params).toString();
    } else if (req.params) {
        url += "?" + req.params;
    }

    location.href = url;

    setTimeout(() => {
        if (currentNativeRequest === req) {
            currentNativeRequest.reject(
                new Error(
                    "Native request timed out: " + currentNativeRequest.path
                )
            );
            currentNativeRequest = null;
            processNativeRequestQueue();
        }
    }, 10000);
}

const ignore =
    import.meta.env.DEV && location.search.includes("ignoreNative=true");

/**
 * Make a request to the native app. Request is queued and processed one at a time.
 * @param {string} path Path to request
 * @param {string | object} params Can pre-encode by passing a string, else will be encoded with URLSearchParams
 * @param {string?} scheme Defaults to "homearweb://"
 * @returns {Promise<any>} Promise that resolves with the response from the native app.
 */
export default async function nativeRequest(
    path,
    params = null,
    scheme = defaultScheme
) {
    if (ignore) return;
    if (!path) throw new Error("path is required");
    return new Promise((resolve, reject) => {
        const req = {
            path: scheme + path,
            params,
            resolve,
            reject,
        };
        console.log("nativeRequest", req);
        nativeRequestQueue.push(req);
        processNativeRequestQueue();
    });
}

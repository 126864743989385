<script>
    import { loginAnon } from "../api";
    import Spinner from "../lib/Spinner.svelte";
    import { visibleCountryStore } from "../store";
    import { onMount } from "svelte";
    import LoginForm from "../lib/LoginForm.svelte";
    import { captureException } from "@sentry/svelte";
    import logo from "@assets/img/logo.svg";
    import Modal from "../lib/Modal.svelte";
    import { localeRegionToCountryCode } from "../constants";

    let showLogin = false;
    let loggingIn = false;
    let loginError = "";

    let showCountrySelection;
    let countryId = 2;

    onMount(async () => {
        const localeBasedCountryId =
            localeRegionToCountryCode[
                new Intl.Locale(navigator.language).region
            ];
        // console.log("localeBasedCountryId: ", localeBasedCountryId);
        if (localeBasedCountryId) {
            await visibleCountryStore.load();
            const foundCode = $visibleCountryStore?.find(
                (country) => country.id === localeBasedCountryId
            );
            if (foundCode) {
                countryId = foundCode.id;
            }
        }
    });

    function tryShowCountrySelection() {
        if (import.meta.env.VITE_DEFAULT_COUNTRY) {
            countryId =
                localeRegionToCountryCode[import.meta.env.VITE_DEFAULT_COUNTRY];
            submitAnon();
        } else {
            showCountrySelection = true;
        }
    }

    async function submitAnon() {
        // Anon sign in
        loggingIn = true;
        loginError = "";
        try {
            await loginAnon(null, countryId);
        } catch (e) {
            loginError = "An unexpected error occurred. Please try again.";
            console.error(e);
            captureException(e);
        } finally {
            loggingIn = false;
        }
    }
</script>

{#if loginError}
    <Modal ok header="Login Error" body={loginError} />
{/if}

<div
    class="StartupAuth flex flex-col items-center w-full h-full fixed top-0 left-0 bg-$color-bg-auth text-$color-text-auth"
>
    <div
        class="flex flex-col w-full max-w-xl gap-4 p-6 pt-[calc(var(--safe-area-top)+1.5rem)]"
    >
        <img src={logo} alt="App logo" class="p-8" />
        {#if loggingIn}
            <div class="flex justify-center"><Spinner /></div>
        {:else if showLogin}
            <button on:click={() => (showLogin = false)} class="flex flex-row">
                <span class="material-symbols-rounded"> chevron_left </span>
                Back
            </button>
            <p class="text-center">
                {@html import.meta.env.VITE_LOGIN_PROMPT}
            </p>
            <LoginForm />
        {:else if showCountrySelection}
            <button
                on:click={() => (showCountrySelection = false)}
                class="flex flex-row"
            >
                <span class="material-symbols-rounded"> chevron_left </span>
                Back
            </button>
            <div class="text-center">Select your country:</div>
            <select class="text-center h-10" bind:value={countryId}>
                {#each $visibleCountryStore as country}
                    <option value={country.id}>{country.name}</option>
                {/each}
            </select>
            <div class="flex flex-col gap-4 text-center text-sm">
                <p>
                    Look out for builders who offer homeAR as part of their
                    service, so you can experience your new home before a brick
                    is laid.
                </p>
                {#if countryId <= 1}
                    <p>
                        You can view home designs from a selection of New
                        Zealand's finest group and independent home builders.
                    </p>
                {:else}
                    <p>
                        In the meantime, make yourself at home in our sample
                        plans, to try the homeAR experience for yourself.
                    </p>
                {/if}
            </div>
            <button class="btn" on:click={submitAnon}>Let's go</button>
        {:else}
            <p class="text-center">
                {@html import.meta.env.VITE_STARTUP_WELCOME}
            </p>
            <button class="btn" on:click={tryShowCountrySelection}>
                {import.meta.env.VITE_STARTUP_SIGNUP_BTN}
            </button>
            <button
                class="btn btn-secondary"
                on:click={() => (showLogin = true)}
            >
                {import.meta.env.VITE_STARTUP_LOGIN_BTN}
            </button>
        {/if}
    </div>
</div>

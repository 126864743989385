<script>
    import { currentNav, showDevOptions, userConfig } from "../store";

    let buttonData = [];

    $: if ($userConfig) {
        const newButtonData = [];
        if ($userConfig.discover) {
            newButtonData.push({
                icon: "home",
                label: "DISCOVER",
                navIndex: 0,
            });
        }
        if ($userConfig.catalogue) {
            newButtonData.push({
                icon: "home",
                label: "CATALOGUE",
                navIndex: 1,
            });
        }
        if ($userConfig.shortlist) {
            newButtonData.push({
                icon: "favorite",
                label: "SHORTLIST",
                navIndex: 2,
            });
        }
        newButtonData.push({
            icon: "person",
            label: "PROFILE",
            navIndex: 3,
        });
        if ($showDevOptions) {
            newButtonData.push({
                icon: "bug_report",
                label: "DEBUG",
                navIndex: 4,
            });
        }
        buttonData = newButtonData;
        $currentNav = buttonData.find((d) => d.navIndex === $currentNav)
            ? $currentNav
            : buttonData[0].navIndex;
    } else {
        buttonData = [];
    }
</script>

<div
    class="bg-$color-bg-footer bg-opacity-85 fixed bottom-0 left-0 w-full h-[calc(var(--safe-area-bottom)+4rem)] pb-$safe-area-bottom z-50 flex flex-row items-center justify-center backdrop-filter backdrop-blur-lg border-t-1 border-t-black border-opacity-15 rounded-t-lg overflow-hidden touch-none"
>
    {#each buttonData as d}
        <button
            class="nav-btn {$currentNav === d.navIndex ? 'nav-btn-active' : ''}"
            on:click={() => ($currentNav = d.navIndex)}
        >
            <div
                class="material-symbols-rounded nav-btn-icon {$currentNav ===
                d.navIndex
                    ? 'material-symbols-rounded-filled'
                    : ''}"
            >
                {d.icon}
            </div>
            <div class="nav-btn-text">{d.label}</div>
        </button>
    {/each}
</div>

<style>
    .nav-btn {
        @apply flex flex-col items-center justify-center flex-grow h-full text-$color-text-footer;
    }

    .nav-btn-active {
        @apply text-$color-primary;
    }

    .nav-btn-icon {
        @apply text-4xl;
    }

    .nav-btn-text {
        @apply text-xs;
    }
</style>
